import "amfe-flexible";
import Vue from "vue";
import App from "./App";
import { router } from "./router";
import { Tabbar, TabbarItem } from "vant";
import "./iconfont/iconfont.css";
Vue.use(Tabbar);
Vue.use(TabbarItem);
// import VueAMap from "vue-amap";
// Vue.use(VueAMap);
import wx from "weixin-js-sdk";
import jquery from 'jquery'
Vue.prototype.$ = jquery
Vue.prototype.$wx = wx;
// VueAMap.initAMapApiLoader({
//   key: "aba72bcd644a5f131588a993301cc96b",
//   plugin: [
//     "AMap.Autocomplete",
//     "AMap.PlaceSearch",
//     "AMap.Scale",
//     "AMap.OverView",
//     "AMap.ToolBar",
//     "AMap.MapType",
//     "AMap.PolyEditor",
//     "AMap.CircleEditor",
//     "AMap.AutoComplete",
//     "AMap.Driving",
//     "AMap.DragRoute",
//     "AMap.Geolocation",
//   ],
//   // 默认高德 sdk 版本为 1.4.4
//   v: "2.0",
// });
import { POST } from "./uits/http";
import { GET } from "./uits/http";

Vue.prototype.postRequest = POST;
Vue.prototype.getRequest = GET;

import navBer from "./view/nav/nav.vue"; //引入组件
Vue.component("navBer", navBer); //注册组件
new Vue({
  router,
  el: "#app",
  render: (h) => h(App),
});
