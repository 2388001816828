<template>
  <div class="nav_cont">
    <ul>
      <li>
        <div
          class="nav_box"
          :class="navIndex == 1 ? 'nav_arr' : ''"
          @click="barFun(1)"
        >
          <div class="icon_box">
            <span class="icon iconfont">&#xe62a;</span>
          </div>
          <div class="ttile">首页</div>
        </div>
      </li>
      <li>
        <div
          class="nav_box"
          :class="navIndex == 2 ? 'nav_arr' : ''"
          @click="barFun(2)"
        >
          <div class="icon_box">
            <span class="icon iconfont">&#xe62b;</span>
          </div>
          <div class="ttile">展场</div>
        </div>
      </li>
      <li>
        <div class="home_box" @click="barFun(1)">
          <div class="tu_box">
            <img src="../../images/home/home_btn.png" alt="" srcset="" />
          </div>
        </div>
      </li>
      <li>
        <div
          class="nav_box"
          :class="navIndex == 3 ? 'nav_arr' : ''"
          @click="barFun(3)"
        >
          <div class="icon_box">
            <span class="icon iconfont">&#xe62c;</span>
          </div>
          <div class="ttile">活动</div>
        </div>
      </li>
      <li>
        <div
          class="nav_box"
          :class="navIndex == 4 ? 'nav_arr' : ''"
          @click="barFun(4)"
        >
          <div class="icon_box">
            <span class="icon iconfont">&#xe62d;</span>
          </div>
          <div class="ttile">购书</div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "navBer",

  data() {
    return {
      navIndex: 1,
    };
  },
  props: {
    navNum: Number,
  },
  created() {
    //console.log("index", this.navNum);
    this.navIndex = this.navNum;
  },
  mounted() {
    // this.navIndex
  },
  methods: {
    barFun(i) {
      // //console.log(i);
      this.navIndex = i;
      switch (i) {
        case 1:
          this.$router.push("/");
          break;
        case 2:
          this.$router.push("/Introduction");
          break;
        case 3:
          this.$router.push("/Activity");
          break;
        case 4:
          this.$router.push("/buyBooks");
          break;
        default:
          break;
      }
    },
  },
};
</script>

<style>
</style>