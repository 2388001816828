import Vue from "vue";
import Router from "vue-router";
import { Toast } from "vant";
Vue.use(Router);

const routes = [
  {
    path: "*",
    redirect: "/home",
  },
  {
    name: "Login",
    component: () => import("./view/Login"),
    meta: {
      title: "2022天府书展",
    },
  },
  {
    name: "home",
    component: () => import("./view/home"),
    meta: {
      title: "2022天府书展",
    },
  },
  {
    name: "Introduction",
    component: () => import("./view/Introduction"),
    meta: {
      title: "会展介绍",
    },
  },
  {
    name: "map",
    component: () => import("./view/map/map.vue"),
    meta: {
      title: "地图",
    },
  },
  {
    name: "Activity",
    component: () => import("./view/Activity/index.vue"),
    meta: {
      title: "活动预告",
    },
  },
  {
    name: "buyBooks",
    component: () => import("./view/buyBooks/index.vue"),
    meta: {
      title: "精选推荐",
    },
  },
  {
    name: "Discounts",
    component: () => import("./view/Discounts/index.vue"),
    meta: {
      title: "领惠民券",
    },
  },
  {
    name: "robDiscount",
    component: () => import("./view/robDiscount/index.vue"),
    meta: {
      title: "定时抢卷",
    },
  },
  {
    name: "zhuchang",
    component: () => import("./view/zhuchang/index.vue"),
    meta: {
      title: "主展场惠民卷",
    },
  },
  {
    name: "zhuchangText",
    component: () => import("./view/zhuchang/text.vue"),
    meta: {
      title: "主展场惠民券指南",
    },
  },
  {
    name: "yunchang",
    component: () => import("./view/yunchang/index.vue"),
    meta: {
      title: "云展场惠民券",
    },
  },
  {
    name: "yunchangText",
    component: () => import("./view/yunchang/text.vue"),
    meta: {
      title: "云展场惠民券指南",
    },
  },
];

// add route path
routes.forEach((route) => {
  route.path = route.path || "/" + (route.name || "");
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }

  next();
});
router.afterEach(() => {
  // 隐藏微信分享按钮
  function onBridgeReady() {
    WeixinJSBridge.call("hideOptionMenu");
  }

  if (typeof WeixinJSBridge == "undefined") {
    if (document.addEventListener) {
     
      document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
    } else if (document.attachEvent) {
      
      document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
      document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
      document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
    }
  } else {
   
    onBridgeReady();
    document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
  }
  window.scrollTo(0, 0);
});
export { router };
